<template>
    <div>
        <product-center-pc v-if="$responsive.is('pc')"></product-center-pc>
        <product-center-mobile v-if="$responsive.is('mobile')"></product-center-mobile>
    </div>
</template>


<script>
    import ProductCenterPC from './product-center-pc';
    import ProductCenterMobile from './product-center-mobile';

    export default {
      components: {
        'product-center-pc': ProductCenterPC,
        'product-center-mobile': ProductCenterMobile,
      },
    }
</script>